<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{  }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Acceptance" :to="{ name: 'all-state-acceptance' }" />
      <b-breadcrumb-item v-if="ensemble" :text="ensemble.name" active />
    </template>

    <template #actions="{ state }">
      <template v-if="canEdit || isCreatedAtInCurrentYear(new Date().toISOString())">
        <can v-if="isEnabled && canEdit" do="update" on="all-state-acceptance">
          <b-button :disabled="!save.changes.length" variant="link" size="sm" @click="saveChanges(state)">
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50"/> Update
          </b-button>
        </can>
      </template>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="ui.layout.showLeftColumn = !ui.layout.showLeftColumn">
        <font-awesome-icon :icon="`fa-solid fa-${ui.layout.showLeftColumn ? 'toggle-on' : 'toggle-off'}`"/>
        <span class="align-middle ml-50">Show Sidebar</span>
      </b-dropdown-item>
      <b-dropdown-item @click="ui.layout.showTooltips = !ui.layout.showTooltips">
        <font-awesome-icon :icon="`fa-solid fa-${ui.layout.showTooltips ? 'toggle-on' : 'toggle-off'}`"/>
        <span class="align-middle ml-50">Show Tooltips</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: ensembles.loading, desc: 'Loading Ensembles', loaded: ensembles.items.length},
          { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <template v-if="state.error || !isEnabled">
        <b-alert v-if="!isEnabled" show variant="danger" class="font-small-3">
          <b-icon-exclamation-circle class="mr-50"/> {{ setting.message.enabled ? setting.message.text : 'Acceptance is currently disabled.' }}
        </b-alert>
        <b-alert v-if="ui.error.show" show variant="danger" class="font-small-3">
          <b-icon-exclamation-circle class="mr-50"/> {{ ui.error.message }}
        </b-alert>
      </template>
      <template v-else>
        <b-row>
          <b-col v-if="ui.layout.showLeftColumn" class="col-12 col-xxxl-3 d-print-none">
            <template>
              <!-- Filters -->
              <b-card title="Filters">
                <b-form-group label="Instrument">
                  <v-select v-model="table.filter.instrument"
                            label="name"
                            :reduce="option => option.id"
                            :options="instruments.items"
                            class="w-100 d-print-none"/>
                </b-form-group>
                <b-form-group label="Part">
                  <v-select v-model="table.filter.part"
                            label="name"
                            :options="getParts()"
                            class="w-100 d-print-none"/>
                </b-form-group>
              </b-card>


              <template v-if="isEnabled && canEdit && $can('update', 'all-state-acceptance')">
                <!-- Pending Changes -->
                <b-alert show variant="primary" class="d-print-none">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Pending Changes ({{ updatableValues.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.changes.expanded ? 'true' : 'false'"
                                    aria-controls="save-details-changes"
                                    class="px-0"
                                    @click="ui.changes.expanded = !ui.changes.expanded">
                            {{ ui.changes.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <!--                      <b-icon icon="circle" variant="primary"/>-->
                        </div>
                      </div>
                      <!-- Element to collapse -->
                      <b-overlay :show="save.processing" variant="white" :opacity="1">
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-collapse id="save-details-changes" v-model="ui.changes.expanded">
                            <b-list-group v-if="updatableValues.length && !save.processing" flush class="pt-50">
                              <b-list-group-item v-for="(item, index) in updatableValues" :key="index" class="px-0 py-25">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div>
                                    <small class="text-capitalize font-weight-bold">{{ item.type }} Selection</small>
                                    <small class="d-block">Selection: {{ item.id }}</small>
                                    <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                      Change <span class="text-capitalize">{{ change.type }}</span> {{ fromToText(change.from, change.to) }}
                                    </small>
                                  </div>
                                  <b-button variant="link" size="sm" class="btn-icon" @click="searchAndExpandItem(item.id)">
                                    <b-icon-search />
                                  </b-button>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                            <small v-else class="pt-50">Nothings been updated yet.</small>
                          </b-collapse>
                        </vue-perfect-scrollbar>
                      </b-overlay>

                    </b-list-group-item>
                  </b-list-group>
                </b-alert>
                <!-- Potential Issues -->
                <b-alert :show="potentialIssues.length > 0" variant="secondary" class="d-print-none">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Potential Issues ({{ potentialIssues.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.potentialIssues.expanded ? 'true' : 'false'"
                                    aria-controls="ui.potentialIssues.expanded"
                                    class="px-0"
                                    @click="ui.potentialIssues.expanded = !ui.potentialIssues.expanded">
                            {{ ui.potentialIssues.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <!--                      <b-icon icon="circle" variant="primary"/>-->
                        </div>
                      </div>
                      <!-- Element to collapse -->
                      <b-overlay :show="save.processing" variant="white" :opacity="1">
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-collapse id="ui.potentialIssues.expanded" v-model="ui.potentialIssues.expanded">
                            <b-list-group flush class="pt-50">
                              <b-list-group-item v-for="(item, index) in potentialIssues" :key="index" class="px-0 py-25">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div>
                                    <small class="text-capitalize font-weight-bold">Duplicate Student</small>
                                    <small class="d-block">{{ item.student.name.first }} {{ item.student.name.last }} appears {{ item.count }} times.</small>
                                  </div>
                                  <b-button variant="link" size="sm" class="btn-icon" @click="searchAndExpandItems(item.student.id)">
                                    <b-icon-search />
                                  </b-button>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </b-collapse>
                        </vue-perfect-scrollbar>
                      </b-overlay>

                    </b-list-group-item>
                  </b-list-group>
                </b-alert>
                <!-- Issues -->
                <b-alert :show="issues.length > 0" variant="danger" class="d-print-none">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Issues ({{ issues.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.issues.expanded ? 'true' : 'false'"
                                    aria-controls="ui.issues.expanded"
                                    class="px-0"
                                    @click="ui.issues.expanded = !ui.issues.expanded">
                            {{ ui.issues.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <!--                      <b-icon icon="circle" variant="primary"/>-->
                        </div>
                      </div>
                      <!-- Element to collapse -->
                      <b-overlay :show="save.processing" variant="white" :opacity="1">
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-collapse id="ui.issues.expanded" v-model="ui.issues.expanded">
                            <b-list-group flush class="pt-50">
                              <b-list-group-item v-for="(item, index) in issues" :key="index" class="px-0 py-25">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div>
                                    <small class="text-capitalize font-weight-bold">{{ item.issue }}</small>
                                    <small class="d-block">{{ item.student.name.first }} {{ item.student.name.last }} can not accept {{ item.count }} times.</small>
                                  </div>
                                  <b-button variant="link" size="sm" class="btn-icon" @click="searchAndExpandItems(item.student.id)">
                                    <b-icon-search />
                                  </b-button>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </b-collapse>
                        </vue-perfect-scrollbar>
                      </b-overlay>

                    </b-list-group-item>
                  </b-list-group>
                </b-alert>
              </template>

            </template>
            <template v-if="state.debug">
              <debug collapsed>
                {{ save.changes }}
              </debug>
              <debug collapsed>
                {{ updatableValues }}
              </debug>
            </template>
          </b-col>
          <b-col>
            <b-overlay :show="save.processing || save.complete" no-center fixed>
              <template #overlay>
                <b-alert show variant="primary" class="d-print-none">
                  <b-list-group>
                    <!-- Cognito User -->
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Processed Changes ({{ save.computedChanges.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.processed.expanded ? 'true' : 'false'"
                                    aria-controls="ui.processed.expanded"
                                    class="mr-1"
                                    @click="ui.processed.expanded = !ui.processed.expanded">
                            {{ ui.processed.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <b-spinner v-if="save.processing" small :class="getSaveClass()"></b-spinner>
                          <b-icon v-else :icon="getSaveIcon(save)" :variant="getSaveVariant(save)" :class="getSaveClass()"/>
                        </div>
                      </div>
                      <div v-if="save.processing" class="my-1">
                        <small class="mb-25">{{ save.status }}</small>
                        <b-progress>
                          <b-progress-bar :value="save.progress" :max="save.computedChanges.length"></b-progress-bar>
                        </b-progress>
                      </div>

                      <!-- Element to collapse -->
                      <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-processed">
                        <b-collapse id="ui.processed.expanded" v-model="ui.processed.expanded">
                          <b-list-group v-if="save.computedChanges.length" flush class="pt-50">
                            <b-list-group-item v-for="(item, index) in save.computedChanges" :key="index" class="px-0 py-25">
                              <div class="d-flex justify-content-between align-items-start">
                                <div>
                                  <small class="text-capitalize font-weight-bold">{{ item.type }} Selection</small>
                                  <small class="d-block">Application: {{ item.id }}</small>
                                  <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                    <span class="text-capitalize">{{ item.type }} {{ change.type }}</span> {{ fromToText(change.from, change.to) }}
                                  </small>
                                  <small class="d-block">
                                    <span class="text-capitalize">{{ item.type }}d {{ item.updatedAt | date }}</span>
                                  </small>
                                  <small v-if="item.error" class="d-block text-danger">Error: {{ item.error }}</small>
                                </div>
                                <b-spinner v-if="item.saving" small></b-spinner>
                                <b-icon v-else :icon="item.icon" :variant="item.variant" class="mt-25"/>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                          <small v-else class="pt-50">Nothings been updated yet.</small>
                        </b-collapse>
                      </vue-perfect-scrollbar>
                    </b-list-group-item>
                  </b-list-group>
                  <div class="d-flex justify-content-end">
                    <b-button v-if="save.complete" block class="mt-50 shadow-none" variant="outline-white" @click="save.complete = false">Back to Table</b-button>
                  </div>
                </b-alert>
              </template>
              <template #default>
                <b-card v-if="!save.processing && !save.complete" no-body class="table-card">
                  <template #header>
                    <b-input-group class="input-group-merge d-print-none">
                      <b-form-input
                          id="search-apps"
                          v-model="table.search"
                          :debounce="250"
                          autocomplete="off"
                          size="md"
                          placeholder="Search Applications"
                      />
                      <b-input-group-append v-if="table.search" is-text>
                        <feather-icon icon="XIcon" class="text-muted" @click="table.search = ''" />
                      </b-input-group-append>
                      <b-input-group-append is-text>
                        <feather-icon icon="SearchIcon" class="text-muted" />
                      </b-input-group-append>
                    </b-input-group>

                  </template>
                  <template #default>
                    <span class="d-none d-print-block p-50 font-weight-bold">{{ ensemble.name }}</span>
                    <validation-observer ref="table-observer">
                      <div id="table-container">
                        <b-table id="table" ref="table"
                                 class="position-relative"
                                 table-class="table-flush page-break"
                                 thead-class="thead-light"
                                 tbody-class="border-0"
                                 details-td-class="td-details-row"
                                 primary-key="id"
                                 responsive
                                 :busy="table.busy"
                                 :items="sortedItems"
                                 :fields="table.fields"
                                 :filter="table.filter"
                                 :filter-included-fields="['group']"
                                 show-empty
                        >
                          <template #table-busy>
                            <slot name="overlay">
                              <overlay-loading :items="[
                                { state: ensembles.loading, desc: 'Loading Ensembles', loaded: instruments.loaded},
                                { state: instruments.loading, desc: 'Loading Instruments', loaded: instruments.loaded},
                                { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
                              ]"/>
                            </slot>
                          </template>

                          <template #row-details="row">
                            <div class="mx-2 mb-1 p-50 rounded bg-light-primary font-small-3">
                              <strong>Selection - </strong>
                              <span class="mr-1">Created:  {{ row.item.createdAt | date }}</span> |
                              <span class="ml-1">Updated: {{ row.item.updatedAt | date }}</span>
                            </div>
                          </template>

                          <template #cell(student)="row">
                            <b-media vertical-align="center" no-body class="d-print-none">
                              <b-media-aside>
                                <b-avatar button
                                          variant="primary" size="2.5em"
                                          badge-variant="primary"
                                          @click="row.toggleDetails">
                                  <!--:button="row.item.otherApplications.length > 0"-->
                                  <font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
                                  <template #badge>
                                    <!--v-if="row.item.otherApplications.length > 0" -->
                                    <b-icon :icon="row.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
                                  </template>
                                </b-avatar>
                              </b-media-aside>
                              <b-media-body class="align-self-center">
                                <h6 class="mb-0">{{ row.item.student.name.first }} {{ row.item.student.name.last }}</h6>
                                <template v-if="row.item.student && row.item.student.school && row.item.student.school.name">
                                  <small class="student-school d-print-none">{{ row.item.student.school.name.legal }}</small>
                                </template>
                                <template v-else>
                                  <small class="student-school d-print-none text-danger">No School</small>
                                </template>
                              </b-media-body>
                            </b-media>
                            <span class="d-none d-print-block">{{ row.item.student.name.first }} {{ row.item.student.name.last }}</span>
                          </template>


                          <template #cell(ensemble)="data">
                            <div v-if="data.item.ensemble">
                              {{ data.item.ensemble.name }}
                            </div>
                            <div v-else class="text-danger">
                              No Ensemble
                            </div>
                          </template>

                          <template #cell(application.instrument)="data">
                            <div v-if="data.item.application.instrument">
                              {{ data.item.application.instrument.name }}
                            </div>
                            <div v-else class="text-danger">
                              No Instrument
                            </div>
                          </template>

                          <template #cell(festival.name)="data">
                            <span class="festival-prefix d-print-none">Festival</span>{{ data.item.festival }}
                          </template>

                          <template #cell(accepted)="data">
                            <validation-provider v-slot="validationContext" :ref="`${data.item.id}-accepted-provider`" :vid="`${data.item.id}-accepted-provider`" name="Accepted" :rules="rules.accepted">
                              <b-form-radio-group v-slot="{ ariaDescribedby }"
                                                  v-model="data.item.accepted"
                                                  :disabled="!isEnabled || !canEdit"
                                                  buttons button-variant="link" class="acceptance-group d-print-none"
                                                  :state="getValidationState(validationContext)"
                                                  @input="onChange('accepted', $event, rules.accepted, data.item)">

                                <b-form-radio :aria-describedby="ariaDescribedby"
                                              :value="false" class="declined pl-0">
                                  <feather-icon v-b-tooltip="{
                                                title: 'Declined',
                                                variant: 'danger',
                                                trigger: 'hover',
                                                disabled: !ui.layout.showTooltips
                                              }" icon="XCircleIcon" size="16"/>
                                </b-form-radio>
                                <b-form-radio :aria-describedby="ariaDescribedby"
                                              :value="null" class="undecided">
                                  <feather-icon v-b-tooltip="{
                                                title: 'Undecided',
                                                variant: 'dark',
                                                trigger: 'hover',
                                                disabled: !ui.layout.showTooltips
                                              }" icon="MinusCircleIcon" size="16"/>
                                </b-form-radio>
                                <b-form-radio :aria-describedby="ariaDescribedby"
                                              :value="true" class="accepted pr-0">
                                  <feather-icon v-b-tooltip="{
                                                title: 'Accepted',
                                                variant: 'success',
                                                trigger: 'hover',
                                                disabled: !ui.layout.showTooltips
                                              }" icon="CheckCircleIcon" size="16"/>
                                </b-form-radio>
                              </b-form-radio-group>
                            </validation-provider>
                            <span class="d-none d-print-block">{{ data.item.accepted }}</span>
                          </template>
                        </b-table>
                      </div>
                    </validation-observer>
                  </template>
                </b-card>
              </template>
            </b-overlay>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Table Items">{{ table.items.slice(0, 20) }}</debug>
        </b-col>
        <b-col>
          <debug title="Table Initial Items">{{ table.initialItems.slice(0, 20) }}</debug>
        </b-col>
        <b-col>
          <debug title="Test">{{ test.slice(0, 20) }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  getEnsemble,
  getEnsembleBySlug,
  getSelection,
  getSetting,
  getUser,
  listEnsembles,
  listInstruments,
  listSelections,
  onCreateSelection,
  onDeleteSelection,
  onUpdateSelection,
  updateSelection
} from './queries/acceptance-table';
import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import {validate} from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import popper from '@/mixins/popper.mixin';
import settingsMixin from '@/mixins/settings.mixin';


export default {
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value)) : null
    }
  },
  components: {
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: null,
      setting: null,
      instrument: null,
      ensemble: null,
      instruments: {
        loading: true,
        items: []
      },
      ensembles: {
        loading: true,
        items: []
      },
      test: [],
      applications: {
        loading: true,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
          { key: 'student', label: 'Student', class: 'col-student' },
          { key: 'ensemble', label: 'Ensemble', class: 'col-ensemble'},
          { key: 'application.instrument', label: 'Instrument', class: 'col-instrument'},
          { key: 'part', label: 'Part', class: 'col-part' },
          { key: 'accepted', label: 'Accepted', class: 'col-accepted' },
        ],
        filter: {
          instrument: null,
          part: null,
        },
        items: [],
        initialItems: [],
        search: null,
        subscription: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showTooltips: true,
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        issues: {
          expanded: false
        },
        error: {
          show: false,
          message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
        accepted: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    isEnabled() {
      const { enabled, override, restrictions } = this.setting;
      const { user } = this;

      if (this.$can('manage')) { return true; }

      // If the current user is in an override group or is an override user then return true
      const canBypassDisabledState = override.groups.some(group => user.groups.includes(group)) || override.users.some(u => user.id === u.id);

      // If the setting is enabled or the user can bypass the disabled state then return true
      if (enabled || canBypassDisabledState) {
        return true; // If enabled, or if the user can bypass the disabled state and is not in a restricted group
      }
      return false; // If disabled and the user cannot bypass the disabled state
    },
    canEdit() {
      const { enabled, override, restrictions } = this.setting;
      const { user } = this;

      if (this.$can('manage')) {
        //console.log('canManage')
        return true;
      }

      // If the current user is in an override group or is an override user then return true
      const canBypassDisabledState = override.groups.some(group => user.groups.includes(group)) || override.users.some(u => user.id === u.id);
      if(canBypassDisabledState) {
        //console.log('canBypassDisabledState')
        return true
      }

      // If the setting is enabled or the user can bypass the disabled state then return true
      if (enabled) {
        //console.log('can edit', enabled, canBypassDisabledState, user, override)
        // If restrictions are enabled then check if the user is in a restricted group
        if(restrictions.enabled) {
          //console.log('restrictions enabled')
          const isInRestrictedGroup = restrictions.groups.some(group => user.groups.includes(group.name));
          //console.log('isInRestrictedGroup', isInRestrictedGroup)
          if (isInRestrictedGroup) {
            const currentDate = new Date();
            return restrictions.groups.filter(group => group.enabled && user.groups.includes(group.name))
              .some(group => {
                try {
                  const startDateParts = group.start.split('-').map(part => parseInt(part, 10));
                  const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);
                  const endDateParts = group.end.split('-').map(part => parseInt(part, 10));
                  const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2], 23, 59, 59, 999)
                  return currentDate >= startDate && currentDate <= endDate;
                }
                catch (e) {
                  console.error('Error parsing date range', e);
                  return false;
                }
              });
          }
        }
        return true; // If enabled, or if the user can bypass the disabled state and is not in a restricted group
      }
      return false; // If disabled and the user cannot bypass the disabled state
    },
    sortedItems() {
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'part',
          'student.id',
          'student.name.full',
          'application.instrument.id'
        ]
      })
      const query = { $and: [ ] }
      if(this.table.filter.instrument) { query.$and.push({'application.instrument.id': `'${this.table.filter.instrument}` }) }
      if(this.table.filter.part) { query.$and.push({part: `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            {id: `'${this.table.search}`},
            {'student.id': `'${this.table.search}`},
            {'student.name.full': `'${this.table.search}`}
          ]
        })
      }

      if(query.$and.length) {
        return fuse.search(query).map(({ item }) => item)
      }
      return this.table.items
    },
    updatableValues() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            selection: { id: item?.id, accepted: item?.accepted },
            type: 'update',
            changes: [],
          }

          const acceptedChange = this.save.changes.find(change => change.id === item.id && change.key === 'accepted')
          if(acceptedChange) {
            input.selection.accepted = acceptedChange.value
            input.changes.push({ type: 'accepted', from: item.accepted, to: acceptedChange.value})
          }
          else { input.selection.accepted = item.accepted }

          /** Determine Date **/
          input.date = new Date(Math.max(...changes.map(change => new Date(change.date)))).toISOString();

          values.push(input)
        }
      })
      return values.sort((a, b) => b.date.localeCompare(a.date))
    },
    potentialIssues() {
      const counter = { }
      this.table.items.forEach(obj => {
        const key = obj.student.id
        counter[key] = (counter[key] || 0) + 1
      })

      const output = []
      Object.entries(counter).forEach(entry => {
        const [key, value] = entry;
        if(value > 1 ) {
          output.push({ student: this.table.items.find(item => item.student.id === key).student, count: value})
        }
      });
      return output
    },
    issues() {
      const items = []
      const uniqueStudents = [...new Map(this.table.items.map((item) => [item.student.id, item.student])).values()];
      uniqueStudents.forEach(student => {
        let count = 0
        const selections = []

        const studentSelections = this.table.items.filter(item => item.student.id === student.id)
        studentSelections.forEach(item => { selections.push({ id: item.id, accepted: item.accepted }) })
        studentSelections.forEach(item => {
          item.otherApplications.forEach(otherApp => {
            if(!selections.find(selection => selection.id === otherApp.selection.id)) {
              selections.push({ id: otherApp.selection.id, accepted: otherApp.selection.accepted })
            }
          })
        })
        const uniqueSelections = [...new Map(selections.map((item) => [item.id, item])).values()]
        count = uniqueSelections.filter(item => item.accepted === true).length
        items.push({ issue: 'Multiple Acceptance', student: student, count: count})
      })
      return items?.filter(item => item.count > 1) || []
    }
  },
  async mounted() {
    //await this.getInstrument()
    await this.getCurrentUser()
    await this.getSetting()
    await this.getEnsemble()

    if(this.isEnabled && !this.$refs.layout.state.error) {
      await this.listEnsembles()
      await this.listInstruments()
      await this.listSelections()
      await this.onCreateSelection()
      await this.onUpdateSelection()
      await this.onDeleteSelection()
    }

    await this.$nextTick(() => {
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  beforeDestroy() {
    if(this.table?.subscriptions?.onCreate) {
      this.table.subscriptions.onCreate.unsubscribe()
    }
    if(this.table?.subscriptions?.onUpdate) {
      this.table.subscriptions.onUpdate.unsubscribe()
    }
    if(this.table?.subscriptions?.onDelete) {
      this.table.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    async refresh() {
      this.table.items = []
      await this.getCurrentUser()
      await this.getSetting()
      await this.getEnsemble()

      if(this.isEnabled && !this.$refs.layout.state.error) {
        await this.listEnsembles()
        await this.listInstruments()
        await this.listSelections()
      }
    },
    async getCurrentUser() {
        /** Get Current User from Store **/
        const cognitoUser = await Auth.currentAuthenticatedUser()

        /** Get User from AppSync **/
        const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
        this.user = response.data.getUser
        this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async getSetting() {
        const response = await API.graphql(graphqlOperation(getSetting, { key: 'selection' }));
        const setting = response.data.getSetting
        if(setting) {
            this.setting = JSON.parse(setting.value)
        }
    },
    async getEnsemble() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getEnsemble, { id: this.id }));
        this.ensemble = response.data.getEnsemble
      }
      else {
        const response = await API.graphql(graphqlOperation(getEnsembleBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.ensemble = response.data.getEnsembleBySlug.items[0]
      }

      this.ensembles.loading = false
      if(this.ensemble) {
        if(this.user.groups.includes('NYSSMA_Chair')) {
          //if the user.ensembleIds doesn't have any matches with the instrument.ensembles.items -> item.id then return an error
          const matches = this.user.ensembleIds.includes(this.ensemble.id)
          if(!matches) {
            this.$refs.layout.state.error = true
            this.ui.error.show = true
            this.ui.error.message = 'Insufficient Permissions - You do not have access to this ensembles acceptance.'
          }
        }
      }
      else {
        this.$refs.layout.state.error = true
        this.ui.error.show = true
        this.ui.error.message = 'Invalid Ensemble Acceptance.'
      }
    },
    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      this.ensembles.items = response.data.listEnsembles.items.sort((a, b) => a.name.localeCompare(b.name));
      this.ensembles.loading = false
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name));
      this.instruments.loading = false
    },
    async listSelections(nextToken, pagedItems) {
      this.table.busy = true
      const items = pagedItems || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          selectionEnsembleId: { eq: this.ensemble.id },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      items.push(...response.data.listSelections.items.filter(item => item?.ensemble?.id !== null && item.ranking.state > 0))

      this.applications.loaded = items.length
      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.applications.loading = false
        this.table.items = this.mapSelections(items) //create table items

        // create initial items. We'll get all the values from the table items, as well as any missing selections that can be found in the other applications
        const initialItems = this.table.items.map(item => ({ id: item.id, accepted: item.accepted }))
        this.table.items.forEach(item => {
          item.otherApplications.forEach(otherApp => {
            initialItems.push({ id: otherApp.selection.id, accepted: otherApp.selection.accepted })
          })
        })
        this.table.initialItems = [...new Map(initialItems.map((item) => [item.id, item])).values()];
        this.table.busy = false
      }
    },
    async onCreateSelection() {
      this.table.subscription.onCreate = API.graphql(graphqlOperation(onCreateSelection)).subscribe(async (sourceData) => {
        const createdSelection = sourceData.value.data.onCreateSelection
        if(createdSelection) {
          const response = await API.graphql(graphqlOperation(getSelection, { id: createdSelection.id }));
          const selection = response.data.getSelection
          if(selection) {
            this.syncNotification()
            const mappedSelection = this.mapSelection(selection)
            this.table.items.push(mappedSelection) //add to table
            this.table.initialItems.push({ id: mappedSelection.id, accepted: mappedSelection.accepted }) //add to initial items so we can track changes
            this.table.items.filter(item => item.id !== mappedSelection.id && item?.student?.id === mappedSelection?.student?.id).forEach(item => { //add the selection as a child selection to table items with the same student id
              item.otherApplications.push({
                id: mappedSelection?.application?.id,
                instrument: mappedSelection?.application?.instrument?.id,
                selection: {
                  id: mappedSelection.id,
                  part: mappedSelection.part,
                  state: mappedSelection.state,
                  ensemble: mappedSelection?.ensemble,
                  accepted: mappedSelection.accepted,
                }
              })
            })
          }
        }
      })
    },
    async onUpdateSelection() {
      this.table.subscription.onUpdate = API.graphql(graphqlOperation(onUpdateSelection)).subscribe(async (sourceData) => {
        const updatedSelection = sourceData.value.data.onUpdateSelection
        if(updatedSelection) {
          const response = await API.graphql(graphqlOperation(getSelection, { id: updatedSelection.id }));
          const selection = response.data.getSelection
          if(selection) {
            this.syncNotification()
            const mappedSelection = this.mapSelection(selection)
            const tableItem = this.table.items.find(item => item.id === mappedSelection.id) || null

            /** Update Table Item if it exists **/
            if(tableItem) {
              tableItem.part = mappedSelection.part
              tableItem.accepted = mappedSelection.accepted
              tableItem.ensemble = mappedSelection.ensemble

              /** if the ensemble was changed and isn't the same id as ensemble we are using we need to remove it so it can't be updated **/
              if(tableItem?.ensemble?.id !== this.ensemble.id) {
                this.table.items = this.table.items.filter(item => item.id !== mappedSelection.id)
                this.save.changes = this.save.changes.filter(change => change.id !== mappedSelection.id)
              }
            }
            /** If there isn't a table item, but the updated selection has the ensemble id we are using, we need to add it to the list **/
            else if(mappedSelection?.ensemble?.id === this.ensemble.id) {
              this.table.items.push(mappedSelection)
            }

            /** Update Other Table Items that include the selection id in the otherApplications.selection **/
            this.table.items.filter(item => item.otherApplications.some(otherApp => otherApp.selection.id === mappedSelection.id)).forEach(item => {
              item.otherApplications.forEach(otherApp => {
                if(otherApp.selection?.id === mappedSelection.id) {
                  otherApp.selection.part = mappedSelection.part
                  otherApp.selection.accepted = mappedSelection.accepted
                  if(otherApp?.selection?.ensemble) {
                    otherApp.selection.ensemble = mappedSelection?.ensemble
                  }
                }
              })
            })

            /** Update Initial Item **/
            const initialItem = this.table.initialItems.find(item => item.id === mappedSelection.id)
            if(initialItem) {
              initialItem.accepted = mappedSelection.accepted
            }
          }
        }
      })
    },
    async onDeleteSelection() {
      this.table.subscription.onDelete = API.graphql(graphqlOperation(onDeleteSelection)).subscribe(async (sourceData) => {
        const deletedSelection = sourceData.value.data.onDeleteSelection
        if(deletedSelection) {
          this.syncNotification()

          // Update Table Items
          this.table.items = this.table.items.filter(item => item.id !== deletedSelection.id) // remove parent selections
          this.table.items.filter(item => item.otherApplications.length > 0).forEach(item => { // remove child selections
            item.otherApplications = item.otherApplications.filter(otherApp => otherApp.selection.id !== deletedSelection.id)
          })

          //remove initial Item
          this.table.initialItems = this.table.initialItems.filter(item => item.id !== deletedSelection.id)

          //remove change
          this.save.changes = this.save.changes.filter(change => change.id !== deletedSelection.id)
        }
      })
    },

    /** Mapping **/
    mapSelections(items) {
      const sortedItems = items.sort((a, b) =>
          a?.application?.instrument?.order - b?.application?.instrument?.order
          || a.part - b.part
          || a?.application?.student?.name?.last.localeCompare(b?.application?.student?.name?.last)
          || a?.application?.student?.name?.first.localeCompare(b?.application?.student?.name?.first))

      return sortedItems.map((item) => this.mapSelection(item));
    },
    mapSelection(item) {
      if(!item) return null
      return {
        id: item.id,
        part: item.part,
        state: item.ranking?.state,
        ensemble: item?.ensemble,
        accepted: item.accepted,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,

        student: {
          id: item?.application?.student?.id,
          name: {
            first: item.application?.student?.name?.first,
            last: item.application?.student?.name?.last,
            full: `${item.application?.student?.name?.first} ${item.application?.student?.name?.last}`
          },
          school: item.application?.student?.school
        },

        application: {
          id: item?.application?.id,
          instrument: item?.application?.instrument,
        },
        otherApplications: item?.application?.student?.applications?.items.filter(otherApp => otherApp.id !== item?.application?.id && otherApp.selection?.ensemble?.id).map(otherApp => ({
          id: otherApp.id,
          instrument: otherApp?.instrument?.id,
          selection: {
            id: otherApp?.selection?.id,
            part: otherApp?.selection?.part,
            state: otherApp?.selection?.ranking?.state,
            ensemble: otherApp?.selection?.ensemble?.id,
            accepted: otherApp?.selection?.accepted,
          }
        })),

      }
    },

    /** Save **/
    async saveChanges() {
      this.table.busy = true
      this.save.complete = false

      //Refresh the settings to make sure that selections are still enabled.
      await this.getSetting()

      if(this.isEnabled) {
        this.save.processing = true
        this.save.status = 'Computing Changes'
        this.save.computedChanges = this.getComputedChanges()

        this.save.status = 'Saving Changes'
        await this.save.computedChanges.reduce(async (referencePoint, change, index) => {
          try {
            change.saving = true
            await referencePoint;
            this.save.progress = index + 1
            if(change.type === 'update') {
              const selectionInput = {
                id: change.selection.id,
                accepted: change.selection.accepted,
              }
              await API.graphql(graphqlOperation(updateSelection, { input: selectionInput } ));
            }
            change.success = true
            change.variant = 'success'
            change.icon = 'check-circle-fill'
            change.updatedAt = new Date().toISOString()
          }
          catch (e121) {
            console.error(e121)
            change.success = false
            change.variant = 'danger'
            change.icon = 'x-circle-fill'
            change.error = e121
            this.save.errors.push({ selection: change.id, error: e121})
          }
          finally {
            change.saving = false
          }
        }, Promise.resolve());


        // Update Initial Items, as well as revert back bad values in the table to their initial value
        this.save.status = 'Updating Initial Items'
        this.save.computedChanges.forEach(change => {
          const initialItem = this.table.initialItems.find(item => item.id === change.id)
          if(initialItem) {
            initialItem.accepted = change.selection.accepted
          }

          const tableItem = this.table.items.find(item => item.id === change.id)
          if(tableItem) {
            tableItem.updatedAt = change?.updatedAt ? change?.updatedAt : tableItem.updatedAt
          }
        })

        this.save.processing = false
        this.save.changes = []
        this.save.complete = true
        this.table.busy = false
      }
      else {
        this.ui.error.show = true
        this.ui.error.message = 'Acceptance has been disabled, all changes made were not be saved.'
      }
    },
    getComputedChanges() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            selection: { id: item?.id, accepted: item?.accepted },
            type: 'update',
            changes: [],
            saving: false,
            success: null,
            error: null,
            icon: 'circle',
            variant: 'primary'
          }

          const acceptedChange = this.save.changes.find(change => change.id === item.id && change.key === 'accepted')
          if(acceptedChange) {
            input.selection.accepted = acceptedChange.value
            input.changes.push({ type: 'accepted', from: item.accepted, to: acceptedChange.value})
          }
          else { input.selection.accepted = item.accepted }

          values.push(input)
        }
      })
      return values
    },

    /** Input **/
    onChange(key, value, rules, selection) {
      validate(value, rules).then(result => {
        this.table.items.filter(item => item.student?.id === selection.student?.id).forEach(item => {
          /*if(item.id !== selection.id && value === true) {
            item.accepted = false
          }*/
/*
          item.otherApplications.forEach(otherApp => {
            if(otherApp.selection.id === selection.id) {
              otherApp.selection.accepted = value
            }
            else if(value === true) {
              otherApp.selection.accepted = false
            }
          })*/
        })

        if (result.valid) {
          const initialItem = this.table.initialItems.find(item => item.id === selection.id)
          if(initialItem?.[key] === value) {
            this.save.changes = this.save.changes.filter(change => !(change.id === selection.id && change.key === key))
          }
          else {
            const changeValue = { id: selection.id, key: key, value: value, date: new Date().toISOString() }
            const index = this.save.changes.findIndex(change => change.id === selection.id && change.key === key)
            if(index >= 0) {
              this.save.changes.splice(index, 1, changeValue)
            }
            else {
              this.save.changes.push(changeValue)
            }
          }
        }
        else {
          this.save.changes = this.save.changes.filter(change => !(change.id === selection.id && change.key === key))
        }
      })
    },
    onBlur(key, item) {
      const initialItem = this.table.initialItems.find(ii => ii.id === item.id)
      if(initialItem && initialItem?.selection[key] === item?.selection[key]) {
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    undoToInitialItem(key, item) {
      const initialItem = this.table.initialItems.find(initial => initial.id === item.id)
      if(initialItem) {
        item.selection[key] = initialItem.selection[key]
        this.save.changes = this.save.changes.filter(change => !(change.id === item.id && change.key === key))
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },

    /** Util **/
    getInputGroupClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return ''
      return state ? 'is-valid' : 'is-invalid'
    },
    fromToText(from, to) {
      return `from ${from !== null ? from : 'null'} to ${to !== null ? to : 'null'}`
    },
    getEnsembleCode(id) {
      return this.ensembles.items.find(item => item.id === id)?.code || ''
    },
    getEnsembleName(id) {
      return this.ensembles.items.find(item => item.id === id)?.name
    },
    getEnsembleById(id) {
      return this.ensembles.items.find(item => item.id === id)
    },
    getInstrumentName(id) {
      return this.instruments.items.find(item => item.id === id)?.name
    },
    getParts() {
      const parts = []
      const ensemble = this.ensembles.items.find(item => item.id === this.ensemble?.id)
      if(ensemble) {
        for (let i = 1; i <= ensemble.parts; i += 1) {
          parts.push(i.toString())
        }
      }
      return parts
    },
    filterEnsembles(options, search) {
      const fuse = new Fuse(options, {
        keys: [ { name: 'code', weight: 1 }, { name: 'name', weight: 0.75} ],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(`${search}`).map(({ item }) => item) : fuse.list
    },
    getSaveIcon(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'check-circle-fill'
        if(save.computedChanges.every(item => item.error)) return 'x-circle-fill'
        return 'slash-circle-fill'
      }
      return 'circle'
    },
    getSaveVariant(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'success'
        if(save.computedChanges.every(item => item.error)) return 'danger'
        return 'warning'
      }
      return 'primary'
    },
    getSaveClass() {
      if(this.save.processing || this.save.complete) {
        if(this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
          return 'mr-2'
        }
      }
      return ''
    },
    searchAndExpandItem(id) {
      this.table.search = id
      const row = this.table.items.find(item => item.id === id)
      if(row) {
        // eslint-disable-next-line no-underscore-dangle
        row._showDetails = true
      }
    },
    searchAndExpandItems(id) {
      this.table.search = id
      this.table.items.filter(item => item.student.id === id).forEach(item => {
        // eslint-disable-next-line no-underscore-dangle
        item._showDetails = true
      })
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
    canUpdate(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  }
}
</script>

<style>
.td-details-row {
  border-top: 0!important;
  padding: 0!important;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}

.b-table-details > td > table > tbody > tr > td {
  border-top: 0!important;
}

.tr-group-2 {
  background-color: #f5f5f5!important;
}
.rounded-bottom-only {
  border-radius: 0 0 0.357rem 0.357rem;
}

.alert .list-group .list-group-item:hover {
  background-color: #fff;
}
.ps-changes {
  max-height: 300px;
}
.ps--active-y.ps-changes {
  padding-right: 1.5rem
}
.ps-processed {
  max-height: 50vh;
}
.ps--active-y.ps-processed {
  padding-right: 1.5rem
}

</style>

<style lang="scss" scoped>
::v-deep {

  .acceptance-group > * {
    color: #b9b9c3  !important;
  }
  .acceptance-group .undecided.active {
    color: #F1C243 !important;
  }
  .acceptance-group .declined.active {
    color: #e42728!important;
  }
  .acceptance-group .accepted.active {
    color: #28c76f!important;
  }

  .col-student {
    width: 30%;
    vertical-align: middle !important;
  }
  .col-ensemble {
    width: 20%;
    padding-left: 0!important;
    padding-right: 0!important;
    vertical-align: middle !important;
  }
  .col-instrument {
    width: 20%;
    padding-left: 0!important;
    padding-right: 0!important;
    vertical-align: middle !important;
  }
  .col-part {
    width: 10%;
    vertical-align: middle !important;
  }
  .col-accepted {
    width: 20%;
    vertical-align: middle !important;
  }

  /*table.page-break{
    page-break-after:always;
    page-break-inside: auto;
    page-break-before: auto;
  }
  table tr.page-break{
    page-break-after:always;
    page-break-inside: auto;
    page-break-before: auto;
  }
  table tr td.page-break{
    page-break-after:always;
    page-break-inside: auto;
    page-break-before: auto;
  }*/


  @media (max-width: 1530px) {
    /*.media-aside {
      margin-right:0!important;
    }
    .b-avatar {
      display: none!important;
    }*/
    /*.col-student {
      width: 24%!important;
    }
    .col-festival {
      width: 10%!important;
    }
    .col-score {
      width: 10%!important;
    }
    .col-local {
      width: 10%!important;
    }*/
    .col-state {
      width: 12%!important;
    }
    .col-ensemble {
      width: 22%!important;
    }
    .col-part {
      width: 12%!important;
    }
    .student-school {
      display: none!important;
    }
    .festival-prefix {
      display: none!important;
    }
  }


  @media print {
    .table-card .card-header {
      padding: 0.5rem!important;
    }
    .table td, .table th {
      padding: 0.5rem!important;
      font-size: 12px!important;
      letter-spacing: normal!important;
      text-transform: none!important;
    }

    /*table {
      page-break-before:unset!important;
      page-break-inside: avoid!important;
      page-break-after:unset!important;
    }*/
    thead {
      page-break-before:unset!important;
      page-break-inside: unset!important;
      page-break-after:unset!important;
    }
    /* tr[role="row"] {
       page-break-before:always!important;
       page-break-inside: avoid!important;
       page-break-after:unset!important;
     }*/
    /*tr[role="row"] td {
      page-break-before:unset!important;
      page-break-inside: avoid!important;
      page-break-after:unset!important;
    }*/

    .card .card-header {
      padding-top: 0!important;
    }
    .form-control {
      border: 0!important;
    }
    .vs__dropdown-toggle {
      border: 0!important;
    }
    .vs__actions {
      display: none!important;
    }
    .media-aside {
      margin-right: 0;
    }
    .b-avatar {
      display: none;
    }
    .col-student {
      width: auto!important;
    }
    .col-festival {
      width: auto!important;
    }
    .col-score {
      width: auto!important;
    }
    .col-local {
      width: auto!important;
    }
    .col-state {
      width: auto!important;
    }
    .col-ensemble {
      width: auto!important;
    }
    .col-part {
      width: auto!important;
    }
  }
}

.v-select::v-deep {
  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 40px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
  }
  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
